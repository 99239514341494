import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import * as REASON from "store/constants/support";
import Layout from "components/Layout/Layout";
import { GENERAL_INQUIRY } from "store/constants/support";
import SupportLandingSection from "components/SupportLanding/SupportLandingSection";
import ContactUsContents from "components/ContactUsContents/ContactUsContents";
import FaqForm from "components/FaqForm/FaqForm";
import ButtonNav from "components/Design/Button/NavButton";
import Icon from "components/CommonIcon/Icon";
import PageContext from "contexts/PageContext";
import { useI18next } from "gatsby-plugin-react-i18next";
import ReachUs from "components/ReachUs/ReachUs";
import { generateSupportURL } from "utils/generateSupportURL";
import { WECHAT_LINK } from "store/constants/link";
import { whatsAppUrl } from "utils/whatsAppUrl";
import { transformObject } from "utils/transformv4";

const LakodiaFaq = ({ data, location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [tabActive, setTabActive] = useState(
    location.hash || location.state?.tabActive
  );

  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    hotelsByLocale: [hotel],
    sections: [destinations],
    pagesByLocale: [{ seo_tags }],
    supportDrawer: [supportDrawer],
  } = transformObject(cms);

  const dataContact = {
    url: generateSupportURL("labuan-bajo/komodo", GENERAL_INQUIRY),
    label: t("Contact Us"),
  };

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  const reachUsLink =
    language === "zh"
      ? WECHAT_LINK
      : whatsAppUrl(hotel.whatsapp_contact, hotel.custom_whatsapp_message);

  return (
    <PageContext.Provider
      value={{ showBannerPadding: true, tabActive: tabActive }}>
      <Layout
        seoTitle={t("Ayana Support")}
        seoTags={seo_tags}
        navigations={navigations}
        hotel={hotel}
        banner={true}
        showBanner={true}
        destinationCards={destinations.images}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with Ayana Komodo")}
        supportDrawer={supportDrawerData}>
        <SupportLandingSection
          title={t("Ayana Support")}
          contactUsContents={<ContactUsContents hotels={hotel.offices} />}
          otherContents={
            <>
              <ReachUs supportReachUsLink={reachUsLink} />
              <ButtonNav
                href={generateSupportURL(
                  "labuan-bajo/komodo",
                  REASON.GENERAL_INQUIRY
                )}
                prefixIcon={<Icon name="comment" />}
                label={t("Contact Us")}
              />
            </>
          }
          supportForm={
            <FaqForm
              faq={hotel.faqs}
              tabActive={tabActive}
              setTabActive={setTabActive}
            />
          }
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query KomodoFaq($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(
      filter: { hotel: { eq: "AYANA Komodo Waecicu Beach" } }
    ) {
      ...LocalNavigation
    }
    allSocialMediaJson(
      filter: { hotel: { eq: "AYANA Komodo Waecicu Beach" } }
    ) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      sections(filters: { name: { eq: "Komodo_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(filters: { name: { eq: "KD_Support_Drawer" } }) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { id: 4 }) {
        data {
          id
          attributes {
            ...NavHotel
            faqs {
              ...CmsFaqsQuestionAnswers_v4
            }
          }
        }
      }
      pagesByLocale(language: $language, where: { name: "Komodo_FAQ" }) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
          }
        }
      }
    }
  }
`;
export default LakodiaFaq;
